import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap"

import { Editor } from "@tinymce/tinymce-react"
import calendar from "../images/calendar.svg"
import UploadFile from "../images/upload-file.svg"
import {
  translate,
  getUserLanguage,
  AUTH_TOKEN_KEY,
  URL_GETALL_ADVANTAGES,
  ALERT_MSG_ERROR,
  URL_IS_AUTH,
  URL_GETALL_ADVICEES,
  URL_ADD_ADVICE,
  DOMAIN_API,
} from "../utils"
import Script from "../components/Script"
import axios from "axios"
import "font-awesome/css/font-awesome.min.css"
import Advice_Comp from "../components/Advice_Comp"
import ModalAlert from "../components/modalAlert"
import $ from "jquery"
import confirmation from "../components/modalConfirm"
import Select from "react-select"
import ButtonMenu from "../components/ButtonMenu"
const Addadvice = ({ location }) => {
  /************* Parameter ************** */
  const [IdSearch, setIdSearch] = useState("")
  const [Data, setData] = useState(null)
  const [DataisLoaded, setDataisLoaded] = useState(false)
  const [DatStart, setDatStart] = useState("")
  const [DatEnd, setDatEnd] = useState("")

  /*********************** */
  const [placeholderDateBegin, setplaceholderDateBegin] = useState("")

  const [placeholderDateEnd, setplaceholderDateEnd] = useState("")
  const [validate, setValidate] = useState(false)
  const [partner, setPartner] = useState()
  const [showAlert, setShowAlert] = useState(false)
  const [alertTitle, setAlertTitle] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [selectValue, setSelectValue] = useState("")
  let data = {}
  const changeBackground = function (e) {
    console.table(window.URL.createObjectURL(e.target.files[0]))
    let url = window.URL.createObjectURL(e.target.files[0])
    
    $(e.target.parentElement.parentElement.parentElement).css(
      "background",
      `url('${url}') no-repeat 100% center`
    )
  }
  const openConfirme = () => {
    confirmation(
      translate("confirmer"),
      translate("voulez vous ajouter cet advice"),
      submitHandler
    )
  }

  /**************Image On change ********************/
  /**********************************/

  const lang = getUserLanguage()

  const submitHandler = async () => {
    // if (!validate) {
    //   return false
    // }
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const header = {
      headers: { Authorization: `Bearer ${token}` },
    }

    let form = new FormData(document.querySelector("form"))
    form.append("lang", lang)
    form.append("partner", partner)
    if (IdSearch) {
      form.append("Id", IdSearch)
      form.append("typeOfAdvice", TypeOf)
    }
    form.forEach((el,key)=>{
      console.table(key,el)
    })

    ///cherif
    axios
      .post(URL_ADD_ADVICE, form, header)
      .then(res => {
        if (res.data == "ok") {
          window.location.href=`/${TypeOf =="dossier" ? "foldes" : TypeOf=="vidéos" ?"videos" :"advices"}`
        } else {
          console.log(res.data)
          setShowAlert(true)
          setAlertTitle(ALERT_MSG_ERROR)
          setAlertMessage(res.data)
        
        }
        
      })
      .catch(err => {
        if (err.response && err.response.data) {
          console.log(err.response.data)
          setShowAlert(true)
          setAlertTitle(ALERT_MSG_ERROR)
          setAlertMessage(err.response.data.message)
        }
        console.log(err)
        //  setTimeout(() => window.location.reload(),3000)
      })
    ///
  }
  const TypeOf = new URLSearchParams(location.search).get("typeOfAdvice")

  useEffect(() => {
    const idS = new URLSearchParams(location.search).get("id")

    setIdSearch(idS)
    if (idS) {
      getInfoAdvice(idS)
    } else {
      setDataisLoaded(true)
    }
    if (TypeOf) {
      setSelectValue(TypeOf)
    }

    if (localStorage.getItem(AUTH_TOKEN_KEY)) {
      auth()
    }
    setplaceholderDateBegin(translate("pick up begin op advantage date"))
    setplaceholderDateEnd(translate("pick up end op advantage date"))

    Script()
  }, [])

  const getInfoAdvice = id => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const data = {
      headers: { Authorization: `Bearer ${token}` },
    }

    axios
      .get(`https://enter-business.com/new/advice?IdAdvice=${id}`)
      .then(res => {
        if (res.data) {
          setData(res.data[0])
          setDatStart(res.data[0].validityDateStart)
          setDatEnd(res.data[0].validityDateEnd)
          setTimeout(() => setDataisLoaded(true), 500)
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data)
        }
        console.log(err)
      })
  }

  const auth = () => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const data = {
      headers: { Authorization: `Bearer ${token}` },
    }
    const url = URL_IS_AUTH
    axios
      .get(url, data)
      .then(res => {
        if (res.data && res.data.role === "user") {
          window.location.href = "/feed"
        } else {
          setPartner(res.data.id)
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data)
        }
        console.log(err)
      })
  }

  const handleEditorChange = (content, editor) => {
    console.log("Content was updated:", content)
  }
  const options = [ 
    { value: "dossier", label: translate("Folder") },
    { value: "vidéos", label: translate("Vidéos") },
    { value: "article", label: translate("Article") },
  ]

  const handelOnchange = (newValue, actionMeta) => {
    setSelectValue(newValue.value)
  }
  const loadHandel = (newValue, actionMeta) => {
    console.table(actionMeta)
    console.table("hey")
  }

  return (
    <Layout>
      <div className="formWrapper label-font-wrap">
        <ButtonMenu />
        <Container>
          <Row className="justify-content-center">
            <Col lg="6" md="12" sm="12" xm="12">
              <div className="text-left">
                <h2 className="mb-3 hideme">
                  {translate(`${IdSearch ? "Edit" : "Add"} an advice`)}
                </h2>
                <form id="msform" encType="multipart/form-data">
                  {/* progressbar */}
                  <ul id="progressbar">
                    {IdSearch ? (
                      " "
                    ) : (
                      <li className="active" id="choice">
                        <strong>{translate("Choice")} </strong>
                      </li>
                    )}
                    <li className={`${IdSearch ? "active" : ""}`} id="account">
                      <strong>{translate("Step 1")} </strong>
                    </li>
                    <li id="personal">
                      <strong>{translate("Step 2")} </strong>
                    </li>
                    <li id="image">
                      <strong>{translate("Step 3")} </strong>
                    </li>

                    <li id="payment">
                      <strong>{translate("Step 4")}</strong>
                    </li>
                  </ul>{" "}
                  {/* fieldsets */}
                  {IdSearch ? (
                    ""
                  ) : (
                    <Col
                      className="sectionStep"
                      lg="12"
                      md="12"
                      sm="12"
                      xm="12"
                    >
                      <Select
                        options={options}
                        defaultValue={
                          options[options.findIndex(it => it.value == TypeOf)]
                        }
                        name="typeOfadvice"
                        onChange={handelOnchange}
                      />
                      {selectValue.trim().length > 0 ? (
                        <Button
                          name="next"
                          id="btn-success-confirm"
                          className="next action-button "
                        >
                          {translate("Next")}
                        </Button>
                      ) : (
                        ""
                      )}
                    </Col>
                  )}
                  {DataisLoaded ? (
                    <Advice_Comp
                      lang={"FR"}
                      TypeOfadvice={selectValue}
                      dataInfo={Data}
                    />
                  ) : (
                    ""
                  )}
                  {DataisLoaded ? (
                    <Advice_Comp
                      lang={"NL"}
                      TypeOfadvice={selectValue}
                      dataInfo={Data}
                    />
                  ) : (
                    ""
                  )}
                  <Col className="sectionStep" lg="12" md="12" sm="12" xm="12">
                    <Row className="pt-4">
                      <Col xl="12">
                        <div
                          className="file-upload-wrap green-aplha-gradient"
                          style={Data ?{
                            backgroundImage: `url("${
                             DOMAIN_API() + Data.s2500x500.trim() 
                            }")`,
                          }: {}}
                        >
                          <span className="top-fixed-tag green-gradient-secondary ">
                            2500 x 500px
                          </span>
                          <div className="mb-3">
                            <Form.File id={`formcheck-api-regular-1-${lang}`}>
                              <Form.File.Label>
                                <img
                                  width={69.22}
                                  height={61.3}
                                  className="img-fluid"
                                  src={UploadFile}
                                  alt=""
                                />
                                {translate("add header")}
                              </Form.File.Label>
                              <Form.File.Input
                                name="s2500x500"
                                onChange={changeBackground}
                              />
                            </Form.File>
                          </div>
                        </div>
                      </Col>
          

                      <Col xl="12">
                        <div
                          className="file-upload-wrap green-aplha-gradient"
                          style={Data ?{
                            backgroundImage: `url("${
                               DOMAIN_API() + Data.s2500x500.trim() 
                            }")`,
                          }: {}}
                        >
                          <span
                            className="top-fixed-tag green-gradient-secondary"
                            
                          >
                            866 x 350px
                          </span>
                          <div className="mb-3">
                            <Form.File id={`formcheck-api-regular-2-${lang}`}>
                              <Form.File.Label style={{ height: "350px" }}>
                                <img
                                  width={69.22}
                                  height={61.3}
                                  className="img-fluid"
                                  src={UploadFile}
                                  alt=""
                                />
                                {translate("add image")}
                              </Form.File.Label>
                              <Form.File.Input
                                name="s866x350Banner"
                                onChange={changeBackground}
                              />
                            </Form.File>
                          </div>
                        </div>
                      </Col>
                      <Col xl="6">
                        <div
                          className="file-upload-wrap green-aplha-gradient"
                          style={  Data ? {
                            backgroundImage: `url(" ${
                              DOMAIN_API() + Data.s420x350Banner 
                            }")`,
                          }: {}}
                        >
                          <span className="top-fixed-tag green-gradient-secondary ">
                          420 x 350px
                          </span>
                          <div className="mb-3">
                            <Form.File id={`formcheck-api-regular-3-${lang}`}>
                              <Form.File.Label style={{ height: "350px" }}>
                                <img
                                  width={69.22}
                                  height={61.3}
                                  className="img-fluid"
                                  src={UploadFile}
                                  alt=""
                                />
                                {translate("add image")}
                              </Form.File.Label>
                              <Form.File.Input
                                name="s420x350Banner"
                                onChange={changeBackground}
                              />
                            </Form.File>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Button
                      name="previous"
                      className="previous action-button-previous"
                    >
                      {translate("Previous")}
                    </Button>

                    <Button name="next" className="next action-button">
                      {translate("Next")}
                    </Button>
                  </Col>
                  <Col className="sectionStep" lg="12" md="12" sm="12" xm="12">
                    <p className="text-secondary d-none">
                      {" "}
                      <strong>{translate("dates of advantage")}</strong>
                      {"(" + translate("unlimited by default") + ")"}{" "}
                    </p>
                    <Row className="d-none">
                      <Col lg="6">
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              <img
                                width={14}
                                height={8}
                                className="img-fluid"
                                src={calendar}
                                alt=""
                              />
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl  className="hide"
                            placeholder={placeholderDateBegin}
                            aria-label=""
                            aria-describedby="basic-addon1"
                            name="validityDateStart"
                            onChange={e => setDatStart(e.target.value)}
                            value={DatStart}
                          />
                        </InputGroup>
                      </Col>
                      <Col lg="6">
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              <img
                                width={14}
                                height={8}
                                className="img-fluid"
                                src={calendar}
                                alt=""
                              />
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            placeholder={placeholderDateEnd}
                            aria-label=""
                            aria-describedby="basic-addon1"
                            name="validityDateEnd"
                            onChange={e => setDatEnd(e.target.value)}
                            value={DatEnd}
                          />
                           <input
                        type="hidden"
                        name="typeOfAction"
                        value={IdSearch ? "Edit" : "Add"}
                      />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Form.Group
                      controlId="formBasicCheckbox"
                      className="d-flex justify-content-center  mb-4"
                    >
                      <Form.Check
                        className="text-certer"
                        type="checkbox"
                        label={translate(
                          "I am sure all the information is correct before save it and make it validate by the Enter Business Team"
                        )}
                        onChange={e => {
                          setValidate(e.target.checked)
                        }}
                      />
                    </Form.Group>
                    <Button
                      name="previous"
                      className="previous action-button-previous"
                    >
                      {translate("Previous")}
                    </Button>
                    <Button
                      name="confirm"
                      className="btn-success bg-success action-button"
                      onClick={openConfirme}
                      disabled={!validate}
                    >
                      {translate("Confirm")}
                    </Button>
                    <Button
                      name="next"
                      id="btn-success-confirm"
                      className=" action-button d-none"
                    ></Button>
                  </Col>
         
                </form>
              </div>
            </Col>
          </Row>
        </Container>
        {showAlert ? (
          <ModalAlert
            alertTitle={alertTitle}
            alertMessage={alertMessage}
            closeModal={() => setShowAlert(false)}
          />
        ) : null}
      </div>
    </Layout>
  )
}

export default Addadvice
