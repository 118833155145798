import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
  Card,
} from "react-bootstrap"
import { Editor } from "@tinymce/tinymce-react"
import calendar from "../images/calendar.svg"
import UploadFile from "../images/upload-file.svg"
import {
  AUTH_TOKEN_KEY,
  DOMAIN_API,
  getUserLanguage,
  translate,
  URL_GETALL_ADVICEES,
} from "../utils"
import { useDropzone } from "react-dropzone"
const Advice_Comp = ({ lang, TypeOfadvice, dataInfo }) => {
  /*************** VALIDATION *************** */
  const [titleValidation, setTitleValidation] = useState("")
  const [subtitleValidation, setSubtitleValidation] = useState("")

  const [ShortdescriptionValidation, setShortdescriptionValidation] = useState("")


  const [tags, settags] = useState("")
  const [Media, setMedia] = useState("")
  const [field, setfield] = useState("")
  // const [Pïctur1, setPïctur1] = useState("")
  // const [Pïctur2, setPïctur2] = useState("")
  // const [Pïctur3, setPïctur3] = useState("")

  /*********************************************************** */
  const [placeholderDescription, setplaceholderDescription] = useState("")
  const [placeholderReduction, setplaceholderReduction] = useState("")
  const [description, setDescription] = useState("")
  const [placeholderMedialink, setplaceholderMedialink] = useState("")

  /******************************* Keys FR OR NL *************/
  let keytitle = `title${lang === "FR" ? "FR" : ""}`
  let keySubtitle = `Subtitle${lang === "FR" ? "FR" : ""}`
  let keydescription = `description${lang === "FR" ? "FR" : ""}`
  let keyshortDescription = `shortDescription${lang === "FR" ? "FR" : ""}`
  let keyfieldsOfActivity = `fieldsOfActivity${lang === "FR" ? "FR" : ""}`
  let keytags = `tags${lang === "FR" ? "FR" : ""}`
  let keyvideoMediaLink = `videoMediaLink${lang === "FR" ? "FR" : ""}`
 
  let keysPdfFile = `PdfFile${lang === "FR" ? "FR" : ""}`
  useEffect(() => {
    if(dataInfo ){
      setTitleValidation(lang==="FR" && dataInfo ? dataInfo.titleFR :dataInfo.title);
      setSubtitleValidation(lang==="FR" && dataInfo ? dataInfo.subTitleFR :dataInfo.subTitle);
      setShortdescriptionValidation(lang==="FR" && dataInfo ? dataInfo.shortDescriptionFR :dataInfo.shortDescription );
      setDescription(lang==="FR" && dataInfo? dataInfo.descriptionFR :dataInfo.description)
     settags(dataInfo  ? lang==="FR" ? dataInfo.tagsFR :dataInfo.tags :"" )
     setMedia(dataInfo  ? lang==="FR" ? dataInfo.videoMediaLinkFR :dataInfo.videoMediaLink :"" )
     setfield(dataInfo  ? lang==="FR" ? dataInfo.fieldsOfActivityFR :dataInfo.fieldsOfActivity :"")
    }


    setplaceholderDescription("_" + translate("description"))
    setplaceholderReduction(translate("-20% your first yearly subscription"))
  
    setplaceholderMedialink(
      "_" + translate("thank you for providing us a Media link (Youtube, …)")
    )
  
  }, [])
  const { acceptedFiles, getRootProps, getInputProps,fileRejections } = useDropzone({

    accept: '.pdf',
    maxFiles:1,
   
  })
  const fileRejectionItems = fileRejections.map(({ file, errors  }) => { 
    return (
      <li className="text-danger" key={file.path}>
           {file.path} - {file.size} bytes
           <ul>
             {errors.map(e => <li className="text-danger" key={e.code}>{translate(e.message)}</li>)}
          </ul>
 
      </li>
    ) 
   });
  const files = acceptedFiles.map(file => (
    <li className="text-success" key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))
  const editorSettings = {
    branding: false,
    height: 500,
    menubar: false,
    placeholder: { placeholderDescription },
    resize: false,
    statusbar: false,
    plugins: [
      "advlist autolink lists link image charmap print preview anchor textcolor",
      "searchreplace visualblocks code fullscreen",
      "media table paste code",
    ],
    toolbar:
      "formatselect | image link media | bold italic forecolor backcolor | \
    alignleft aligncenter alignright alignjustify | \
    bullist numlist outdent indent | code",
  }

  return (
    <Col className="sectionStep" lg="12" md="12" sm="12" xm="12">
      <h4 className="mb-4">
        {" "}
        {lang == "FR"
          ? translate("Information en Francais")
          : translate("Information en Nedernalds")}
      </h4>
      <div className="text-left">
        <Form.Group>
          <Form.Label>
            <strong>{translate("title of the advice")}</strong>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={placeholderReduction}
            name={keytitle}
            value={titleValidation }
            onChange={e => setTitleValidation(e.target.value)}
            isInvalid={titleValidation.length === 0}
          />
          <Form.Control.Feedback type="invalid">
            {translate("le titre doit etre remplie")}
          </Form.Control.Feedback>
        </Form.Group>


        <Form.Group>
          <Form.Label>
            <strong>{translate("Sub title of the advice")}</strong>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={placeholderReduction}
            name={keySubtitle}
            value={subtitleValidation }
            onChange={e => setSubtitleValidation(e.target.value)}
            isInvalid={subtitleValidation.length === 0}
          />
          <Form.Control.Feedback type="invalid">
            {translate("le sous titre doit etre remplie")}
          </Form.Control.Feedback>
        </Form.Group>


        <Form.Group>
          <Form.Label>
            <strong>{translate("short Description")}</strong>
          </Form.Label>
          <Form.Control
            as="textarea"
            placeholder={"short Description"}
            name={keyshortDescription}
            value={ShortdescriptionValidation}
            onChange={e => setShortdescriptionValidation(e.target.value)}
            isInvalid={ShortdescriptionValidation.length === 0}
          />
          <Form.Control.Feedback type="invalid">
            {translate("la courte description doit etre remplie")}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          controlId="exampleForm.ControlTextarea1"
          isInvalid={ShortdescriptionValidation}
        >
          <Form.Label>
            <strong>{translate("description")}</strong>
          </Form.Label>
          <Editor
            apiKey="apya3p5m6gnmb567a82qd5y4t221zeihzbdsw9mml629y2s8"
            init={editorSettings}
             value={description }
            onChange={e => {
              setDescription(e.target.getContent())
            }}
          />
          <input type="hidden" value={description} name={keydescription} />
        </Form.Group>
        <Form.Group  className="hideme">
          <Form.Label>
            <strong>{translate("fields Of Activity")}</strong>
          </Form.Label>
       
          <Form.Control as="select" onChange={e=>setfield(e.target.value)} value={field}  name={keyfieldsOfActivity}>
            <option value={translate("services aux entreprises")}>
              {translate("services aux entreprises")}
            </option>
            <option value={translate("services immobilier")}>
              {translate("services immobilier")}
            </option>
          </Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label>
            <strong>{translate("tags")}</strong>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={"tags as('business','industry')"}
            name={keytags}
            value={tags}
            onChange={e=>settags(e.target.value)}
          />
        </Form.Group>
        {TypeOfadvice === "dossier" ? (
          <>
           <p className="text-secondary">
              <strong>{translate("do you want to add a Pdf" + "?")}</strong>
             
            </p>
          <Card   className="container mb-4 mt-4">
            <div   {...getRootProps({ className: "dropzone  text-center drag-zone" })}>
              <input name={keysPdfFile} {...getInputProps()} />
              <p>{translate("Drag and drop one PDF file here, or click to select file")}</p>
            </div>
            <aside>
              <h4>Files</h4>
              <ul>{files}</ul>
              <h4 className="mt-2">{translate("Rejected files")}</h4>
                <ul>{fileRejectionItems}</ul>
            </aside>
          </Card></>
        ) : (
          <>
            <p className="text-secondary">
              <strong>{translate("do you want to add a video" + "?")}</strong>
              
            </p>
            <Form.Group>
            <input
                type="hidden"
                value={
                  titleValidation.trim().length === 0 ||
                  ShortdescriptionValidation.trim().length === 0 ||
                  description.trim().length === 0
                    ? "N"
                    : "T"
                }
                name={`${lang}`}
              />
            </Form.Group>
            <Form.Group>

              <FormControl
                aria-label=""
                placeholder={placeholderMedialink}
                name={keyvideoMediaLink}
                onChange={e=>setMedia(e.target.value)}
                value={Media}
    
              />
            </Form.Group>
          </>
        )}

   </div>
      {lang=="FR" && dataInfo ? "":
      <Button name="previous" className="previous action-button-previous">
        {translate("Previous")}
      </Button>
      }
      <Button
        name="next"
        disabled={
          titleValidation.trim().length === 0 ||
          ShortdescriptionValidation.trim().length === 0 ||
          subtitleValidation.trim().length === 0 ||
          description.trim().length === 0
        }
        className="next action-button"
      >
        {translate("Next")}
      </Button>
    </Col>
  )
}

export default Advice_Comp
