import React from 'react'
import $ from "jquery"

const Script = () => {


    $(document).ready(function(){

        var current_fs, next_fs, previous_fs; //fieldsets
        var opacity;
        
        $(document).on('click','.next',function(){
        
            $("body,html").animate({
                scrollTop:0
                  }, 1000);
        current_fs = $(this).parent();
        next_fs = $(this).parent().next();
        
        //Add Class Active
        $("#progressbar li").eq($(".sectionStep").index(next_fs)).addClass("active");
        
        //show the next fieldset
        next_fs.show();
        //hide the current fieldset with style
        current_fs.animate({opacity: 0}, {
        step: function(now) {
        // for making fielset appear animation
        opacity = 1 - now;
        
        current_fs.css({
        'display': 'none',
        'position': 'relative'
        });
        next_fs.css({'opacity': opacity});
        },
        duration: 600
        });
        });
        
        $(document).on('click',".previous",function(){
        
        current_fs = $(this).parent();
        previous_fs = $(this).parent().prev();
        
        $("body,html").animate({
            scrollTop:0
              }, 1000);
        //Remove class active
        $("#progressbar li").eq($(".sectionStep").index(current_fs)).removeClass("active");
        
        //show the previous fieldset
        previous_fs.show();
        
        //hide the current fieldset with style
        current_fs.animate({opacity: 0}, {
        step: function(now) {
        // for making fielset appear animation
        opacity = 1 - now;
        
        current_fs.css({
        'display': 'none',
        'position': 'relative'
        });
        previous_fs.css({'opacity': opacity});
        },
        duration: 600
        });
        });
        
        $('.radio-group .radio').click(function(){
        $(this).parent().find('.radio').removeClass('selected');
        $(this).addClass('selected');
        });
        
        $(".submit").click(function(){
        return false;
        })
        
        });
}

export default Script
